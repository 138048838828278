// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.
@use "./styles/tema" as mse-tema;
@use "./styles/exportar" as mse-exportar;
@use "./styles/mat-card" as mse-card;

// Tipografía
$mse-tipografia: mat.define-typography-config(
  $font-family: "Syne",
);
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($mse-tipografia);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mse-primario: mat.define-palette(mse-tema.$paleta-azul, 500);
$mse-acento: mat.define-palette(mse-tema.$paleta-naranja, 500);

// The warn palette is optional (defaults to red).
$mse-advertencia: mat.define-palette(mse-tema.$paleta-rojo);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mse-tema: mat.define-light-theme(
  (
    color: (
      primary: $mse-primario,
      accent: $mse-acento,
      warn: $mse-advertencia,
    ),
    typography: $mse-tipografia,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mse-tema);
@include mse-card.tema($mse-tema);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Syne", "Helvetica Neue", sans-serif;
  @each $nombre, $valor in mse-exportar.$colores {
    @include mse-exportar.define-nombre-propiedad($nombre, $valor);
  }
}
